import { z } from 'zod';
import PaymentFrequency from './PaymentFrequency';

const searchParamsSchema = z.object({
  paymentFrequency: z
    .nativeEnum(PaymentFrequency)
    .catch(PaymentFrequency.MONTHLY),
  amount: z
    .string()
    .transform(val => (val.length ? Number(val) : 2500))
    .catch(2500),
  isCustomAmount: z.coerce
    .string()
    .transform(val => val === 'true')
    .catch(false),
  isCustomAmountSelected: z.coerce
    .string()
    .transform(val => val === 'true')
    .catch(false),
  sessionId: z.string().catch(''),
  emoji: z.string().catch('prayerHands'),
});

export default searchParamsSchema;
