import { useState } from 'react';
import currency from 'currency.js';

const MoneyInput = ({
  value = '0.00',
  onChangeText,
}: {
  value?: string;
  onChangeText: (value: string) => void;
}) => {
  const [numValue, setNumValue] = useState(
    currency(value).format({ symbol: '', separator: '' }),
  );

  const handleValueChange = (text: string) => {
    setNumValue(text);
    if (onChangeText) {
      return onChangeText(moneyToNumber(text).toString());
    }
  };

  return (
    <>
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <span className="text-black sm:text-sm">$</span>
      </div>
      <input
        type="text"
        name="price"
        id="price"
        className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 outline-none placeholder:text-gray-400 sm:text-sm sm:leading-6"
        placeholder="1.00"
        onChange={e => {
          handleValueChange(e.target.value);
        }}
        value={numValue !== '0.00' ? normalize(numValue) : ''}
      />
    </>
  );
};

// converts "210.900" to "2109.00"
function normalize(priceString: string): string {
  const priceInt = moneyToNumber(priceString).toFixed(2);
  return String(priceInt);
}

// forces two decimal places. "1.00"
function moneyToNumber(money: string) {
  const stripped = money ? money.replace('.', '') : '';

  const isZero = (x: string) =>
    (x.length < 4 || x == '0000') && parseInt(x, 10) == 0;

  return isZero(stripped) || stripped === '' ? 0 : parseInt(stripped, 10) / 100;
}

export default MoneyInput;
