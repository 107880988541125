import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { useLoaderData } from '@remix-run/react';
import type { Stripe } from '@stripe/stripe-js';

interface StripeCheckoutProps {
  stripePromise: Promise<Stripe | null>;
}

const StripeCheckout = ({ stripePromise }: StripeCheckoutProps) => {
  const { clientSecret } = useLoaderData<{
    clientSecret: string;
  }>();

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        key={clientSecret}
        stripe={stripePromise}
        options={{
          clientSecret,
        }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default StripeCheckout;
