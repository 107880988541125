import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import currency from 'currency.js';
import MoneyInput from './MoneyInput';
import { Button } from '@/components/ui/Button';

function customAmountValidator(min: number, max: number) {
  const underMinMessage = `You cannot donate less than $${min}`;
  const overMaxMessage = `You cannot donate more than $${currency(max).format({
    symbol: '',
    separator: ',',
  })}`;

  return (money?: number) => {
    const { isUnder, isOver, isValid } = validate(min, max, money);

    return isValid
      ? true
      : isUnder
        ? underMinMessage
        : isOver
          ? overMaxMessage
          : false;
  };
}

function validate(
  min: number,
  max: number,
  money?: number,
): { isUnder: boolean; isOver: boolean; isValid: boolean } {
  const _isValid = (x: number) => x >= min && x <= max;

  const _isOver = (x: number) => x > max;
  const _isUnder = (x: number) => x < min;

  return {
    isUnder: _isUnder(money || 0),
    isOver: _isOver(money || 0),
    isValid: _isValid(money || 0),
  };
}

const CustomAmountForm = ({
  recurringInterval,
  onValueSelected,
}: {
  recurringInterval: string;
  onValueSelected: (value: number) => void;
}) => {
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      amount: 1,
    },
    delayError: 200,
  });
  const amount = watch('amount');

  const [prevAmount, setPrevAmount] = useState(1);

  useEffect(() => {
    if (amount != prevAmount) {
      setPrevAmount(amount);
    }
  }, [amount, prevAmount]);

  const onSubmit = (form: { amount: number }) => {
    // return in cents
    const amountCents = form.amount * 100;
    onValueSelected(amountCents);
  };

  const frequencyOptionText =
    recurringInterval === 'year'
      ? 'annual'
      : recurringInterval === 'month'
        ? 'monthly'
        : 'one-time';

  return (
    <div className="mt-4">
      <label className="block font-bold leading-6 text-gray-900">
        Amount <span className="font-normal">({frequencyOptionText})</span>
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <Controller
          name="amount"
          control={control}
          rules={{
            validate: customAmountValidator(1, 999999.99),
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <MoneyInput
                onChangeText={newValue => {
                  if (newValue != undefined) {
                    onChange(currency(newValue).value);
                  }
                }}
                value={value.toString()}
              />
            </>
          )}
        />
      </div>
      <p className="mt-2 flex font-bold text-red-800">
        {errors.amount?.message}
      </p>
      <Button
        variant="secondary"
        disabled={!isValid}
        onClick={handleSubmit(onSubmit)}
        className="w-full mt-4"
      >
        Select
      </Button>
    </div>
  );
};

export default CustomAmountForm;
