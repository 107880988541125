import { useSearchParams } from '@remix-run/react';
import type { Stripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import CustomAmountForm from './CustomAmountForm';
import PaymentFrequencyButton from './PaymentFrequencyButton';
import StripeCheckout from './StripeCheckout';
import PaymentFrequency from '@/routes/index/PaymentFrequency';
import searchParamsSchema from '@/routes/index/searchParamsSchema';
import { Button } from '@/components/ui/Button';
import { CloseIcon } from '@/assets/icons';
import { emojiOptions } from '@/utils/emojis';

interface PaymentOption {
  cents: number;
  frequency: PaymentFrequency;
}

const paymentOptions: PaymentOption[] = [
  {
    cents: 10000,
    frequency: PaymentFrequency.ONE_TIME,
  },
  {
    cents: 30000,
    frequency: PaymentFrequency.ONE_TIME,
  },
  { cents: 50000, frequency: PaymentFrequency.ONE_TIME },
  {
    cents: 2500,
    frequency: PaymentFrequency.MONTHLY,
  },
  {
    cents: 5000,
    frequency: PaymentFrequency.MONTHLY,
  },
  {
    cents: 10000,
    frequency: PaymentFrequency.MONTHLY,
  },
];

const getFormattedCurrencyAmount = (
  amount: number,
  { maximumFractionDigits = 2 } = {},
) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits,
  }).format(amount / 100);
};

const getPaymentOptions = (frequency: PaymentFrequency) =>
  paymentOptions.filter(option => option.frequency === frequency);

const getDefaultPaymentOption = (frequency: PaymentFrequency) =>
  getPaymentOptions(frequency)[0];

interface PaymentFormsProps {
  stripePromise: Promise<Stripe | null>;
}

const PaymentForm = ({ stripePromise }: PaymentFormsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDonateByCheckOpen, setIsDonateByCheckOpen] = useState(false);
  const {
    paymentFrequency,
    isCustomAmount,
    amount,
    isCustomAmountSelected,
    emoji,
  } = searchParamsSchema.parse({
    paymentFrequency: searchParams.get('paymentFrequency'),
    amount: searchParams.get('amount'),
    isCustomAmount: searchParams.get('isCustomAmount'),
    isCustomAmountSelected: searchParams.get('isCustomAmountSelected'),
    emoji: searchParams.get('emoji'),
  });
  const selectedPaymentOptions = getPaymentOptions(paymentFrequency);

  const handleFrequencyOptionClick = (frequency: PaymentFrequency) => {
    const defaultPaymentOption = getDefaultPaymentOption(frequency);
    setSearchParams(prev => {
      prev.set('amount', defaultPaymentOption.cents.toString());
      prev.set('paymentFrequency', frequency);
      prev.delete('isCustomAmount');
      return prev;
    });
  };

  const handleCustomAmountClick = () => {
    setSearchParams(prev => {
      prev.set('isCustomAmount', 'true');
      prev.set('isCustomAmountSelected', 'false');
      prev.delete('amount');
      return prev;
    });
  };

  const handleCustomAmountSelectedClick = (value: number) => {
    setSearchParams(prev => {
      prev.set('amount', value.toString());
      prev.set('paymentFrequency', paymentFrequency);
      prev.set('isCustomAmount', 'true');
      prev.set('isCustomAmountSelected', 'true');
      return prev;
    });
  };

  const handlePaymentOptionClick = (option: PaymentOption) => {
    setSearchParams(prev => {
      prev.set('isCustomAmount', 'false');
      prev.set('amount', option.cents.toString());
      return prev;
    });
  };

  const handleSelectEmojiOption = (emojiOption: string) => {
    setSearchParams(prev => {
      prev.set('emoji', emojiOption);
      return prev;
    });
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'fundraiseup';
    script.innerHTML = `
      (function(w,d,s,n,a){if(!w[n]){var l='call,catch,on,once,set,then,track'
.split(','),i,o=function(n){return'function'==typeof n?o.l.push([arguments])&&o
:function(){return o.l.push([n,arguments])&&o}},t=d.getElementsByTagName(s)[0],
j=d.createElement(s);j.async=!0;j.src='https://cdn.fundraiseup.com/widget/'+a;
t.parentNode.insertBefore(j,t);o.s=Date.now();o.v=4;o.h=w.location.href;o.l=[];
for(i=0;i<7;i++)o[l[i]]=o(l[i]);w[n]=o}
})(window,document,'script','FundraiseUp','AKKXEFHL');
      `;
    document.body.appendChild(script);

    return () => document.getElementById('fundraiseup')?.remove();
  }, []);

  return (
    <div className="rounded-2xl flex flex-col w-full md:max-w-[467px] p-4 md:mt-40">
      <div className="from-[#FFFFF0] to-[#D3EDFE] bg-gradient-to-b p-6 rounded-t-2xl md:pl-10 md:pr-10">
        <h2 className="mb-4 text-2xl font-general-sans-semibold not-italic text-[#252525] leading-7	md:mt-6">
          Choose donation type:
        </h2>
        <div className="flex justify-center mb-4">
          <PaymentFrequencyButton
            frequency={PaymentFrequency.ONE_TIME}
            selectedFrequency={paymentFrequency}
            onClick={handleFrequencyOptionClick}
            text="ONE-TIME"
          />
          <PaymentFrequencyButton
            frequency={PaymentFrequency.MONTHLY}
            selectedFrequency={paymentFrequency}
            onClick={handleFrequencyOptionClick}
            text="MONTHLY"
          />
        </div>
        <h2 className="mb-4 text-2xl font-general-sans-semibold not-italic text-[#252525] leading-7	md:mt-6">
          Choose an amount to give:
        </h2>
        <div className="flex justify-around md:justify-between mb-4 w-full">
          {selectedPaymentOptions.map(item => {
            const isSelected = item.cents === amount;

            return (
              <Button
                key={item.cents}
                variant={isSelected ? 'secondary' : 'defaultWhite'}
                onClick={() => handlePaymentOptionClick(item)}
                className="flex flex-col w-[90px] md:w-[100px] h-[90px] md:h-[110px] rounded-[10px]"
              >
                <span className="text-3xl/[38.4px] font-semibold">
                  {getFormattedCurrencyAmount(item.cents, {
                    maximumFractionDigits: 0,
                  })}
                </span>
                <span className="text-lg lowercase font-medium">
                  {item.frequency === PaymentFrequency.MONTHLY
                    ? 'month'
                    : 'one-time'}
                </span>
              </Button>
            );
          })}
        </div>
        <Button
          variant={isCustomAmount ? 'secondary' : 'defaultWhite'}
          onClick={handleCustomAmountClick}
          className="w-full normal-case	"
        >
          Custom Amount
        </Button>
        <div className="w-full text-center my-4 font-general-sans-semibold text-[#252525] relative">
          <button onClick={() => setIsDonateByCheckOpen(prev => !prev)}>
            *Donate by check
          </button>
          <div
            className={`w-[300px] md:w-[367px] h-[308px] bg-[rgb(255,255,240)] rounded-[20px] border-[#252525] border-[1px] flex items-center justify-center flex-col cursor-default ${isDonateByCheckOpen ? 'absolute' : 'hidden'} z-50`}
          >
            <div className="flex justify-end w-full relative">
              <CloseIcon
                className="fill-[#252525] absolute -top-8 right-6 cursor-pointer"
                onClick={() => setIsDonateByCheckOpen(false)}
              />
            </div>
            <div className="flex flex-col md:text-left">
              <p className="text-xl">Check donation:</p>
              <p className="flex flex-col w-[328px] font-general-sans-medium text-lg">
                <span className="my-4">
                  To donate by check, <br />
                  please mail your donations to:
                </span>
                <span>IF: Gathering</span>
                <span>PO Box 515782</span>
                <span>Dallas Tx 75251</span>
              </p>
            </div>
          </div>
        </div>
        <h2 className="mb-4 text-2xl font-general-sans-semibold not-italic text-[#252525] leading-7	md:mt-6">
          Emoji Selection :
        </h2>
        <div></div>
        <div className="flex justify-around md:justify-between w-full mb-4">
          {Object.entries(emojiOptions).map(([key, item]) => {
            const isSelected = key === emoji;

            return (
              <Button
                key={key}
                variant={isSelected ? 'secondary' : 'defaultWhite'}
                onClick={() => handleSelectEmojiOption(key)}
                className="flex flex-col w-[77px] h-[55px] rounded-[10px] font-[26px]"
              >
                {item}
              </Button>
            );
          })}
        </div>
        <div>
          {isCustomAmount && !isCustomAmountSelected && (
            <CustomAmountForm
              recurringInterval={paymentFrequency}
              onValueSelected={handleCustomAmountSelectedClick}
            />
          )}
        </div>
      </div>
      {(!isCustomAmount || (isCustomAmount && isCustomAmountSelected)) && (
        <div className="h-full w-full bg-white p-10 bg-gradient-to-b">
          <StripeCheckout stripePromise={stripePromise} />
        </div>
      )}
      <div className="flex flex-col from-[#FFFFF0] to-[#D3EDFE] bg-gradient-to-b justify-center p-10 rounded-b-2xl">
        <p className="p-2 text-center text-sm">
          IF Gathering is a 501(c)3 non-profit organization ©{' '}
          {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default PaymentForm;
